.Card-container {
    cursor: pointer;
    position: relative;
    font-size: 3.5rem;
    font-weight: 700;
    min-width: 8rem;
    padding: 0rem;
    height: 12rem;
    transition: all .3s ease-out;
    transform: scale(1);
    border: 2px solid black;
    background-color: white;
    /*opacity: .95;*/
}
.Card-inner_div {
    position: relative;
    height: 12rem;
}

.Card-rank {
    position: relative;
    left: .25rem;
    top: -1.2rem;
    letter-spacing: 0px;
}
.Card-10 {
    font-size: 3.4rem;
    left: 0rem;
    top: -.9rem;
    padding-bottom: .35rem;
}
.Card-9 {
    left: .50rem;
}

.Card-suit-top {
    position: relative;
    top: -4.8rem;
    font-size: 5.5rem;
}

.Card-suit-bottom {
    font-size: 5.5rem;
    transform: rotateX(180deg);
    position: absolute;
    bottom: -2rem;
    right: 0rem;
    padding: 0;
    margin: 0;
    height: 5.5rem;
}


.Card-container:hover {
    z-index: 1000;
    /*opacity: 1;*/
    transform: scale(1.25) translate(1rem);
}
.Card-container-item_clicked {
    /*height: 12rem;*/
    /*z-index: 1000;*/
    /*opacity: 1;*/
    /*transform: scale(1.25) translate(1rem);*/
    /*background-color: #81c784;*/
    /*border: 3px solid black;*/
}
.Card-clickedOne {
    /*z-index:1000;*/
    /*top: 13rem;*/
    /*left: 8rem;*/
    /*background-color: #81c784;*/
}

@media screen and (max-width: 626px) {
    .Card-container {
        font-size: 2rem;
        /*font-weight: 700;*/
        min-width: 6.5rem;
        padding: 0rem;
        height: 10rem;
        transition: all .3s ease-out;
        transform: scale(1);
        border: 2px solid black;
        background-color: white;
        /*opacity: .95;*/
    }
    .Card-rank {
        position: relative;
        left: .25rem;
        top: -.5rem;
        letter-spacing: 0px;
    }
    .Card-suit-top {
        position: relative;
        top: -2.5rem;
        font-size: 3.5rem;
    }
    .Card-suit-bottom {
        font-size: 3.5rem;
        bottom: 1.05rem;
    }
    .Card-10 {
        font-size: 2rem;
        padding-bottom: 0rem;
    }
    /*.Card-container-item_clicked {*/
        /*height: 10rem;*/
    /*}*/
}

@media screen and (max-width: 500px) {
    .Card-suit-top {
        position: relative;
        font-size: 2.5rem;
        top: -2rem;
        left: .3rem;
    }
    .Card-suit-bottom {
        font-size: 2.5rem;
        bottom: 1.65rem;
        right: .4rem;

    }
}