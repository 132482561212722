* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  width: 100vw !important;
  max-width: 100vw !important;
  font-size: 62.5%;
}

main {
  width: 100vw !important;
  max-width: 100vw !important;
}

body {
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.Active-Safe-Completed {
  color: #00E676;
  font-weight: 700;
  font-size: 130%;
}
.bold-text {
  font-weight: 700;
}

.red-suit {
  font-size: 130%;
  color: #8d0018;
}
.black-suit {
  font-size: 130%;
  color: black;
}

.modal {
  min-width: 95%;
}