.Bidding-container {
    position: relative;
    /*min-height: 30rem;*/
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    max-height: 17rem;
    max-width: 32rem;
    /*max-width: 35rem;*/
    
    /*min-height: 15rem;*/

    /*background-color: #0b5714;*/
    overflow: auto;
}

.Bidding-header_item {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    border: .1rem solid black;
    border-radius: 25%;
}

.Bidding-entry {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    height: 3rem;
    font-size: 1.65rem;
    /*font-size: 200%;*/
    font-weight: bold;
    text-align: center;
    border: .1rem solid black;
    border-radius: 25%;
    /*margin: 2rem;*/
    /*padding: .2rem;*/
    /*margin: .1rem;*/
    background-color: #CACACA;
}

.Bidding-entry-artificial {
    background-color: #f4ca00;
    border: .1rem dashed black;
}

.Bidding-entry-next {
    font-size: 1.85rem;
    height: 3rem;
    /*border: .5rem solid #0D47A1;*/
}
.Bidding-entry-blank {
    border: none;
    background: none;
}


.Bidding-NoTrump {
    /*height: 3.5rem;*/
    position:relative;
    /*top: .35rem;*/
    font-size: 100%;
}


@media screen and (max-width: 500px) {
    .Bidding-entry-next {
        height: 3rem;
    }

    .Bidding-entry-suit {
        text-align: left;
        font-size: 1.75rem;
    }
    .Bidding-NoTrump {
        /*height: 2rem;*/
        font-size: 80% !important;
    }

    .Bidding-entry {
        position: relative;

        left: -1rem;

        /*top: 1rem;*/
        font-size: 1.35rem !important;
    }
    .Bidding-entry-next {
        font-size: 2rem !important;
    }
    .Bidding-container {
        /*max-width: 100%;*/
        /*width: 11rem;*/
        height: 100%;
        /*max-height: 100%;*/
    }
    .black-suit-bidding {
        font-size: 1.4rem !important;
    }
    .red-suit-bidding {
        font-size: 1.4rem !important;
    }
    .Bidding-NoTrump-bidding {
        font-size: 1.4rem !important;
    }
}

@media screen and (max-width: 385px) {
    .Bidding-header_item {
        font-size: 1.25rem;
    }
    .Bidding-entry {
        font-size: 1.25rem;
    }
}
