.DisplayQuiz-container {
  /*transform: scale(1.2);*/
  transition: all 0.35s ease-out;
  font-size: 1.6rem;
}

.DisplayQuiz-quiz_question {
  padding-bottom: 3rem;
}
.DisplayQuiz-ans {
  position: relative;
  /* top: 6.6rem !important; */
  font-size: 3rem !important;
  margin-bottom: 1.5rem;
}

.DisplayQuiz-scoring {
  font-size: 2.4rem !important;
  position: relative;
  top: -2.5rem !important;
  text-align: center;
  font-weight: bold;
  /*margin-bottom: 8rem;*/
}
.DisplayQuiz-completed {
  background-color: forestgreen !important;
  top: 4.1rem !important;
}

.DisplayQuiz-content_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.DisplayQuiz-biddingInputArea {
  padding: 0;
  position: relative;
  padding-top: 5.5rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.DisplayQuiz-biddingGrid {
  top: -3rem;
  position: relative;
  left: 3rem;
}
.DisplayQuiz-hand_container {
  /*width: 100vw;*/
  /*padding: 0 !important;*/
  /*padding: 0;*/
  /*margin: 0;*/
  /*!*width: 100vw;*!*/
  /*display: flex;*/
  /*align-content: space-around;*/
  /*justify-content: space-around;*/
  /*align-items: space-around;*/
  /*display: flex;*/

  /*position: relative;*/
  padding-top: 5rem;
  padding-left: 5rem;
  position: relative;
}
.DisplayQuiz-hand {
  /*display: flex;*/
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.DisplayQuiz-submit {
  position: absolute;
  bottom: -2.5rem;
  left: 4.55rem;
  font-size: 1.9rem !important;
  text-transform: capitalize !important;
  /* left: 5.8rem; */
}
/*.DisplayQuiz-submit-openingLead-container {*/
/*position: absolute;*/
/*width: 100vw;*/
/*display: flex;*/
/*align-items: center;*/
/*justify-content: center;*/
/*}*/
.DisplayQuiz-submit-openingLead {
  /*position: absolute;*/
  /*bottom: -2rem;*/
  /*left: 14rem;*/

  position: absolute;
  bottom: -3rem;
  left: 5rem;
}

.DisplayQuiz-quizType {
  width: 12rem;
  font-size: 1.3rem;
  text-align: center;
  background-color: #78909c;
  position: absolute;
  font-weight: bold;
  color: white;
  top: 0rem;
  left: 10.5rem;
}

@media screen and (max-width: 857px) {
  .DisplayQuiz-content_container {
    display: inherit;
  }
  .DisplayQuiz-biddingGrid {
    margin-top: 2rem;
  }
  .DisplayQuiz-quiz_question {
    padding-bottom: 2rem;
  }

  .DisplayQuiz-biddingInputArea {
    padding-top: 0rem;
    position: relative;
    top: 0.25rem;
  }
  .DisplayQuiz-hand_container {
    padding-left: 1rem;
  }
  .DisplayQuiz-submit-openingLead {
    left: 1.5rem;
  }
  .DisplayQuiz-ans {
    position: relative;
    /* top: 6.6rem !important; */
    font-size: 3rem !important;
    margin-bottom: 5.25rem;
  }
}

@media screen and (max-width: 400px) {
  .DisplayQuiz-quizType {
    left: 0;
  }
  .DisplayQuiz-biddingGrid {
    left: 2.5rem;
    top: -5.5rem;
    position: relative;
  }
  .DisplayQuiz-hand_container {
    margin-top: 2rem;
    /*padding-top: 2rem;*/
    /*padding-top: 4.5rem;*/
    /*padding-left: 1rem;*/
    /*position: relative;*/
    /*top: 2rem;*/
  }
  /*.DisplayQuiz-hand {*/
  /*margin-top: 2rem;*/
  /*}*/

  .DisplayQuiz-submit-openingLead {
    /*top: -1rem;*/
    /*left: 3.5rem;*/
  }
  /*.DisplayQuiz-submit-openingLead-container {*/
  /*!*left: 0rem;*!*/
  /*}*/
  /*.DisplayQuiz-submit {*/
  /*position: relative;*/
  /*top: -6.5rem;*/
  /*}*/

  /*.Display-board_container {*/
  /*!*padding-left: 1.5rem;*!*/
  /*}*/
}
