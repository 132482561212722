.PremiumMembership-container {
    position: relative;
    /*padding: 5rem 10rem 10rem;*/
    padding: 2rem 90rem 2rem;
    background-color: #F5F5F5;
}

.PremiumMembership-signup_button {
    position: relative;
    font-weight: bold;
    top: -9.50rem;
    font-size: 2rem;
    background-color: #0f9d58;
    width: 15rem;
    height: 5rem;
    text-transform: capitalize;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .2);
}

.PremiumMembership-signup_button i {
    font-size: 2rem;
}

.PremiumMembership-signup_button:hover,
.PremiumMembership-signup_button:focus,
.PremiumMembership-signup_button:active {
    background-color: black;
    color: white;
}


.PremiumMembership-card_outer_container div.card-content {
    padding: .1rem 2rem .5rem;
}

.PremiumMembership-signup_form {
    z-index: 2000;
}

#paypal_loading {
    position: relative;
    color: rgba(0, 0, 0, .3);
    font-size: 2.5rem;
    font-weight: bold;
    animation-iteration-count: infinite;

}
.PremiumMembership-details {
    padding-top: 0rem;
    border-top: 8px dotted lightgray;
    border-bottom: 8px dotted lightgray;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    background-color: whitesmoke;
    padding: 1rem !important;
    /*grid-column-gap: 3rem !important;*/
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, .5);
    /*padding-top: 1rem;*/
    /*margin: 0rem !important;*/
    /*margin-top: 0rem !important;*/
}

#paypal_loading:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    color: #0f9d58;
    animation: loading 3s linear;
    animation-iteration-count: infinite;
}
@keyframes loading {
    0% {
        max-width: 0;
    }
}

.PremiumMembership-loading {
    width: 100%;
    font-size: 2rem;
    padding-bottom: 2rem;
    border-bottom: 3px dotted lightgray;
}

.PremiumMembership-header_overlay {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 12rem;
    background-image: linear-gradient(105deg,
        rgba(255, 255, 255, .9) 0%,
        rgba(255, 255, 255, .9) 50%,
        transparent 50%),
        url(./img/background-2238731_640.jpg);
        /*url(./img/playing-card-568201_640.jpg);*/
    background-size: 100%;

    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    /*background-size: cover;*/

    clip-path: polygon(0% 77%, 42% 100%, 60% 100%, 100% 77%, 100% 0%, 100% 0%, 0% 0%, 0% 0%);
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .2);

    /*opacity: .1;*/
}

/*.PremiumMembership-header_overlay_textcaption {*/
/*position: absolute;*/
/*width: 32rem;*/
/*z-index: 4;*/
/*font-size: 65%;*/
/*top: -3rem;*/
/*background-color: black;*/
/*color: white;*/
/*font-weight: bold;*/
/*text-align: center;*/
/*border-radius: 3px;*/
/*box-shadow: 0 1.5rem 4rem rgba(0,0,0,.2);*/
/*!*opacity: .1;*!*/
/*}*/

.PremiumMembership-header_overlay_shadow {
    position: absolute;
    content: "";
    z-index: 2;
    top: .75rem;
    left: .75rem;
    width: 100%;
    height: 11rem;
    background-color: rgba(0, 0, 0, 0.7);
    /*background-color: black;*/
    /*background-size: 100%;*/

    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    /*background-size: cover;*/

    clip-path: polygon(0% 77%, 42% 100%, 60% 100%, 100% 77%, 100% 0%, 100% 0%, 0% 0%, 0% 0%);
    /*border-radius: 3px;*/
    /*box-shadow: 0 1.5rem 4rem rgba(0,0,0,.2);*/

    /*opacity: .1;*/
}
.PremiumMembership-token-input-box {
    all: revert !important;
    /*border: 1px solid #9e9e9e;*/
    border-radius: 0 !important;
    outline: none !important;
    /*height: 3rem;*/
    /*width: 100%;*/
    /*font-size: 1rem;*/
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;

    border: 1px dotted #999 !important;
    height: 2rem !important;
    font-size: 1.8rem !important;
    width: 18.3rem !important;
    height: 4.9rem !important;
    padding-right: .2rem !important;
}
.PremiumMembership-token-button {
    transition: all .9s ease-in-out;
    /*opacity: 1 !important;*/
}

.PremiumMembership-large-icon {
    font-size: 4.2rem !important;
    /*opacity: 1 !important;*/
}

.PremiumMembership-trial_string {
    font-weight: bold;
    font-size: 2.5rem;
    cursor: pointer;
    color: #0f9d58;
    position: relative;
    top: -9.5rem;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.PremiumMembership-trial_string_subtext {
    font-size: 1.7rem;
    font-weight: 425;
}

.PremiumMembership-token-input {
    margin-bottom: 2rem;
    transition: all .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;

    padding: 0;
    margin: 0;
    position: relative;
    /*top: -10rem;*/
    top: -1.25rem;
    margin-bottom: 1rem;
    margin-top: 2.9rem;
}
.PremiumMembership-token-input-submitted {
    background-color: rgba(245,245,245,.75);
}
.PremiumMembership-token-input-invalid {
    border: 2px solid darkred;
}
    /*border: 1px dotted #999;*/

.PremiumMembership-paypal_signup_button {
    width: 30.2rem;
    top: -9.9rem;
    height: auto;
    position: relative;
    cursor: pointer;
    margin-bottom: 1.6rem;
}

/*.PremiumMembership-paypal_signup_button:hover {*/
    /*filter: invert(100%);*/
/*}*/
/*.PremiumMembership-signup_button_cc:hover {*/
    /*filter: none;*/
/*}*/
.PremiumMembership-secure_checkout {
    font-size: 1.5rem;
    position: relative;
    top: -16.4rem;
    font-weight: bold;
    /*margin-bottom: 1.5rem;*/
    /*padding-bottom: 1rem;*/
}

.PremiumMembership-signup_button_cc {
    font-size: 1.47rem;
    font-weight: 900;
    text-decoration: none;
    text-transform: none;
    width: 29.5rem;
    top: -15rem;
    height: 5rem;
    position: relative;
    cursor: pointer;
    background-color: orangered;
    color: white;
}

.PremiumMembership-signup_button_cc:hover {
    background-color: black;
    /*color: yellow;*/
}
.PremiumMembership-signup_button_cc:focus,
.PremiumMembership-signup_button_cc:active {
    background-color: orangered;
}

.PremiumMembership-signup_button_preloader {
    position: relative;
    top: .65rem;
}
.PremiumMembership-signup_button_loading {
    background-color: black;
}



.PremiumMembership-tick {
     font-weight: bold;
     font-size: 3rem;
     color: #0f9d58;
     margin: 0;
     position: absolute;
     top: 1.5rem;
     left: 50%;
     margin-right: -50%;
     transform: translate(-50%, -50%);
     padding: 0 !important;
 }
.PremiumMembership-tick-basic {
    font-weight: bold;
    font-size: 2rem;
    color: #0f9d58;
    /*margin: 0;*/
    position: absolute;
    top: 1.5rem;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.PremiumMembership-header {
    text-align: center;
    font-size: 3.15rem;
    font-weight: bold;
    padding: 1rem;
    padding-bottom: 3rem;
}

.PremiumMembership-header_text {
    text-align: left;
    font-size: 2.2rem;
    /*padding: 1rem 4rem 4rem;*/
    padding: 2rem;
    padding-bottom: 4rem;
}
.PremiumMembership-header_text_small {
    text-align: left;
    font-size: 2.2rem;
    /*padding: 1rem 4rem 4rem;*/
    padding: 2rem;
    padding-bottom: 1rem;
}

.PremiumMembership-logo-container {
    position: relative;
    text-align: center;
    font-size: 4rem;
    padding: 1rem;
    height: 20rem;
    /*height: 120rem;*/
    border-bottom: 3px dotted lightgray;
    /*background-color: #F5F5F5;*/
}

.PremiumMembership-title {
    font-family: 'Dancing Script', cursive;
}

.PremiumMembership-signupText {
    /*font-family: 'Dancing Script', cursive;*/
    z-index: 1001;
    position: relative;
    top: -31rem;

    /*position:absolute;*/
    /*top: -2rem;*/
    /*left: 21.5rem;*/

    font-size: 1.8rem;
    width: 25rem;
    margin: 0 auto;
    /*border: 1px solid black;*/
    border: 3px dotted lightgray;
    /*border-bottom: 3px dotted lightgray;*/
    background-color: whitesmoke;
}
.PremiumMembership-signupText-after {
    z-index: 1001;
    /*position: relative;*/
    /*top: -30.5rem;*/

    /*position:absolute;*/
    /*top: -2rem;*/
    /*left: 21.5rem;*/
    position: relative;
    top: 2.3rem;
    font-size: 2rem;
    width: 25rem;
    margin: 0 auto;
    /*border: 1px solid black;*/
    border: 3px dotted lightgray;
    /*border-bottom: 3px dotted lightgray;*/
    background-color: whitesmoke;
}

/*PremiumMembership-details-card {*/
    /*clip-path: polygon(0 0, 100% 0, 100% 200px, 0 100%);*/
/*}*/

.PremiumMembership-login_area {
    /*position: absolute;*/
    /*top: -.35rem;*/
    z-index: 1000;
    border-bottom: 3px dotted lightgray;
}

.PremiumMembership-text-row {
    position: relative;
    text-align: center;
    /*max-width: 50rem;*/
    /*position: relative;*/
    /*left: 2.7rem;*/
    /*top: -2.3rem;*/
    /*border-bottom: 1px dotted lightgray;*/
}

.PremiumMembership-row {
    /*text-align: center;*/
    /*background-image: linear-gradient(to right bottom, lightgrey, white);*/

    padding-top: 3rem;
    position: relative;
    padding-bottom: 1rem;
    font-weight: 300;
    font-size: 1.6rem;
    border-bottom: 3px dotted lightgray;
    margin-bottom: 0 !important;
    text-align: left !important;
}
/*.PremiumMembership-row:nth-child(4n) {*/
    /*background-color: white;*/
/*}*/
/*.PremiumMembership-row:nth-child(1) {*/
    /*background-color: white;*/
/*}*/
/*.PremiumMembership-row:last-child {*/
    /*background-color: white;*/
/*}*/
@media screen and (max-width: 2203px) {
    .PremiumMembership-container {
        padding: 2rem 65rem 2rem;
    }
}
@media screen and (max-width: 1703px) {
    .PremiumMembership-container {
        padding: 2rem 55rem 2rem;
    }
}
@media screen and (max-width: 1600px) {
    .PremiumMembership-container {
        padding: 2rem 45rem 2rem;
    }
}
@media screen and (max-width: 1350px) {
    .PremiumMembership-container {
        padding: 2rem 25rem 2rem;
    }
}

@media screen and (max-width: 1080px) {
    .PremiumMembership-container {
        padding: 2rem 10rem 2rem;
    }
}
@media screen and (max-width: 758px) {
    .PremiumMembership-container {
        padding: 2rem 2rem 2rem;
    }
}

@media screen and (max-width: 550px) {
    .PremiumMembership-header_text_small {
        font-size: 1.6rem;
    }
    .PremiumMembership-trial_string {
        font-size: 1.8rem;
    }
    .PremiumMembership-header {
        padding-top: 2.3rem;
        font-size: 2.3rem;
    }
    .PremiumMembership-container {
        padding: 0rem;
    }
    .PremiumMembership-logo-container {
        padding: 0rem;
    }

    .PremiumMembership-logosmall-B,
    .PremiumMembership-logosmall-C {
        font-size: 150%;
    }
    .PremiumMembership-signupText {
        top: -30.5rem;
    }
    .PremiumMembership-signup_button {
        top: -8.3rem;
        left: .5rem;
    }

}

@media screen and (max-width: 400px) {
    .PremiumMembership-secure_checkout {
        top: -15.2rem;
    }
    .PremiumMembership-logosmall-B,
    .PremiumMembership-logosmall-C {
        font-size: 1rem;
    }

    .PremiumMembership-signup_button {
        font-size: 1.5rem;
        width: auto;
        height: auto;
    }

    .PremiumMembership-signup_button i {
        font-size: 1.5rem;
    }

    .PremiumMembership-signupText {

        font-size: 1.8rem;
        width: 55%;
    }
}

.PremiumMembership-logosmall-B,
.PremiumMembership-logosmall-C {
    font-family: 'Dancing Script', cursive;
    font-size: 180%;
    font-weight: bold;
    text-align: center;
    z-index: 4;
}

.PremiumMembership-logosmall-B {
    position: relative;
    left: -1rem;
    top: -1.5rem;
    /*margin: 0 auto;*/
    /*position: absolute;*/
    /*top: -5rem;*/
    /*left: 22rem;*/
}

.PremiumMembership-logosmall-C {
    position: relative;
    left: .8rem;
    top: -10rem;
    /*margin: 0 auto;*/
    /*position: absolute;*/
    /*top: 0rem;*/
    /*left: 26rem;*/
}

@media screen and (max-width: 364px) {
    .PremiumMembership-paypal_signup_button {
        /*top: -8rem;*/
        padding-top: 1rem;
        width: 20rem;
        height: 5.5rem;
    }
    .PremiumMembership-signup_button_cc {
        width: 19.7rem;
        height: 45%;
    }
    .PremiumMembership-signup_button {
        top: -9.65rem;
    }
    .PremiumMembership-signup_button i {
        position: relative;
        right: .75rem;
        padding: 0;
        padding-right: 0rem;
        margin: 0;
        /*font-size: 1.2rem;*/
    }

    .PremiumMembership-signupText {
        width: 100%;
    }
}


