.ArticlesListItem-div_wrapper {
    position: relative;
}

.ArticlesListItem-container {
    z-index: 1;
    min-width: 25.4rem;
    position: relative;
    font-size: 1.4rem;
    margin: 2rem 1rem 2rem;
    cursor: pointer;
    padding: 4rem;
    padding-top: 5rem;
    border-radius: 3.5%;
}

/*.ArticleListItem-teaser {*/
    /*max-width: 100%;*/
/*}*/

.ArticleListItem-created_at {
    background-color: #0D47A1;
    padding: 1.1rem;
    color: white;
    position: absolute;
    font-size: 1.35rem;
    font-weight: bold;
    right: 1.5rem;
    top: 0;
}


.ArticleListItem-title {
    font-size: 2.4rem;
    font-weight: bold;
    color: #0D47A1;
}
.ArticleListItem-category {
    width: 12rem;
    font-size: 1.3rem;
    text-align: center;
    background-color: #78909c;
    position: absolute;
    font-weight: bold;
    color: white;
    top: 2.2rem;
    left: 1.5rem;
}
.ArticleListItem-difficulty {
    width: 12rem;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    font-weight: bold;
    color: white;
    top: 0;
    left: 1.5rem;
}
.ArticlesListItem-difficulty-beg {
    background-color: #0f9d58;
}
.ArticlesListItem-difficulty-int {
    background-color: #b86607;
}
.ArticlesListItem-difficulty-adv {
    background-color: #8d0018;
}
.ArticlesListItem-difficulty-general {
    background-color: #0D47A1;
}

.ArticleListItem-teaser_board {
    /*position: relative;*/
    /*right: -3rem;*/
    max-width: 30rem;
}
.ArticleListItem-teaser_board_full {
    max-width: 100%;
}

@media screen and (max-width: 585px) {
    .ArticleListItem-teaser_board_full {
        position: relative;
        right: 3.95rem;
        max-width: 95vw;
        width: 100vw;
    }
}


/*@media screen and (min-width: 800px) {*/
    /*.ArticlesListItem-container {*/
        /*margin: 0rem 0rem 0rem;*/
        /*padding: 0;*/
    /*}*/
/*}*/