textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #4caf50;
  -webkit-box-shadow: 0 1px 0 0 #4caf50;
  box-shadow: 0 1px 0 0 #4caf50;
}

textarea.materialize-textarea:focus.invalid ~ .helper-text[data-error],
.select-wrapper.valid .helper-text[data-success],
.select-wrapper.invalid ~ .helper-text[data-error] {
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

textarea.materialize-textarea:focus.valid ~ .helper-text:after,
.select-wrapper.valid ~ .helper-text:after {
  content: attr(data-success);
  color: #4caf50;
}

textarea.materialize-textarea:focus.invalid ~ .helper-text:after,
.select-wrapper.invalid ~ .helper-text:after {
  content: attr(data-error);
  color: #f44336;
}

textarea.materialize-textarea + label:after,
.select-wrapper + label:after {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}
.input-field.inline .select-dropdown {
  margin-bottom: 1rem;
}

.select-label {
  position: absolute;
}

.select-wrapper {
  position: relative;
}

.select-wrapper.valid + label,
.select-wrapper.invalid + label {
  width: 100%;
  pointer-events: none;
}

.select-wrapper input.select-dropdown {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}

.select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid #26a69a;
}

.select-wrapper .caret {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 0;
  fill: rgba(0, 0, 0, 0.87);
}

.select-wrapper + label {
  position: absolute;
  top: -26px;
  font-size: 0.8rem;
}

select:disabled {
  color: rgba(0, 0, 0, 0.42);
}

.select-wrapper.disabled + label {
  color: rgba(0, 0, 0, 0.42);
}

.select-wrapper.disabled .caret {
  fill: rgba(0, 0, 0, 0.42);
}

.select-wrapper input.select-dropdown:disabled {
  color: rgba(0, 0, 0, 0.42);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-wrapper i {
  color: rgba(0, 0, 0, 0.3);
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

body.keyboard-focused .select-dropdown.dropdown-content li:focus {
  background-color: rgba(0, 0, 0, 0.08);
}

.select-dropdown.dropdown-content li:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.select-dropdown.dropdown-content li.selected {
  background-color: rgba(0, 0, 0, 0.03);
}

.prefix ~ .select-wrapper {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.prefix ~ label {
  margin-left: 3rem;
}

.select-dropdown li img {
  height: 40px;
  width: 40px;
  margin: 5px 15px;
  float: right;
}

.select-dropdown li.optgroup {
  border-top: 1px solid #eee;
}

.select-dropdown li.optgroup.selected > span {
  color: rgba(0, 0, 0, 0.7);
}

.select-dropdown li.optgroup > span {
  color: rgba(0, 0, 0, 0.4);
}

.select-dropdown li.optgroup ~ li.optgroup-option {
  padding-left: 1rem;
}

/* Modal */
.datepicker-modal {
  max-width: 325px;
  min-width: 300px;
  max-height: none;
}

.datepicker-container.modal-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
}

.datepicker-controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 280px;
  margin: 0 auto;
}

.datepicker-controls .selects-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.datepicker-controls .select-wrapper input {
  border-bottom: none;
  text-align: center;
  margin: 0;
}

.datepicker-controls .select-wrapper input:focus {
  border-bottom: none;
}

.datepicker-controls .select-wrapper .caret {
  display: none;
}

.datepicker-controls .select-year input {
  width: 50px;
}

.datepicker-controls .select-month input {
  width: 70px;
}

.month-prev,
.month-next {
  margin-top: 4px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

/* Date Display */
.datepicker-date-display {
  -webkit-box-flex: 1;
  -webkit-flex: 1 auto;
  -ms-flex: 1 auto;
  flex: 1 auto;
  background-color: #26a69a;
  color: #fff;
  padding: 20px 22px;
  font-weight: 500;
}

.datepicker-date-display .year-text {
  display: block;
  font-size: 1.5rem;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.7);
}

.datepicker-date-display .date-text {
  display: block;
  font-size: 2.8rem;
  line-height: 47px;
  font-weight: 500;
}

/* Calendar */
.datepicker-calendar-container {
  -webkit-box-flex: 2.5;
  -webkit-flex: 2.5 auto;
  -ms-flex: 2.5 auto;
  flex: 2.5 auto;
}

.datepicker-table {
  width: 280px;
  font-size: 1rem;
  margin: 0 auto;
}

.datepicker-table thead {
  border-bottom: none;
}

.datepicker-table th {
  padding: 10px 5px;
  text-align: center;
}

.datepicker-table tr {
  border: none;
}

.datepicker-table abbr {
  text-decoration: none;
  color: #999;
}

.datepicker-table td {
  border-radius: 50%;
  padding: 0;
}

.datepicker-table td.is-today {
  color: #26a69a;
}

.datepicker-table td.is-selected {
  background-color: #26a69a;
  color: #fff;
}

.datepicker-table td.is-outside-current-month,
.datepicker-table td.is-disabled {
  color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.datepicker-day-button {
  background-color: transparent;
  border: none;
  line-height: 38px;
  display: block;
  width: 100%;
  border-radius: 50%;
  padding: 0 5px;
  cursor: pointer;
  color: inherit;
}

.datepicker-day-button:focus {
  background-color: rgba(43, 161, 150, 0.25);
}

/* Footer */
.datepicker-footer {
  width: 280px;
  margin: 0 auto;
  padding-bottom: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
  color: #26a69a;
  padding: 0 1rem;
}

.datepicker-clear {
  color: #f44336;
}

/* Media Queries */
@media only screen and (min-width: 601px) {
  .datepicker-modal {
    max-width: 625px;
  }
  .datepicker-container.modal-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .datepicker-date-display {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 270px;
    -ms-flex: 0 1 270px;
    flex: 0 1 270px;
  }
  .datepicker-controls,
  .datepicker-table,
  .datepicker-footer {
    width: 320px;
  }
  .datepicker-day-button {
    line-height: 44px;
  }
}

.pulse {
  overflow: visible;
  position: relative;
}

.pulse::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index: -1;
}

@-webkit-keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

/* Checkbox stuff */
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

.input-field.col .dropdown-content [type="checkbox"] + label {
  top: 1px;
  left: 0;
  height: 18px;
  -webkit-transform: none;
  transform: none;
}

.dropdown-trigger {
  cursor: pointer;
}

/* Checkboxes
   ========================================================================== */
/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="checkbox"] {
  /* checkbox aspect */
}

[type="checkbox"] + span:not(.lever) {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type="checkbox"] + span:not(.lever):before,
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 3px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="checkbox"]:not(:checked):disabled + span:not(.lever):before {
  border: none;
  background-color: rgba(0, 0, 0, 0.42);
}

[type="checkbox"].tabbed:focus + span:not(.lever):after {
  -webkit-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"]:checked + span:not(.lever):before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"]:checked:disabled + span:before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + span:not(.lever):before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"]:indeterminate:disabled + span:not(.lever):before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  background-color: transparent;
}

[type="checkbox"].filled-in + span:not(.lever):after {
  border-radius: 2px;
}

[type="checkbox"].filled-in + span:not(.lever):before,
[type="checkbox"].filled-in + span:not(.lever):after {
  content: "";
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
    height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
    height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0px;
  z-index: 0;
}

[type="checkbox"].filled-in:checked + span:not(.lever):before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}

[type="checkbox"].filled-in.tabbed:focus + span:not(.lever):after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a;
}

[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border-color: transparent;
  background-color: #949494;
}

[type="checkbox"].filled-in:disabled:checked + span:not(.lever):before {
  background-color: transparent;
}

[type="checkbox"].filled-in:disabled:checked + span:not(.lever):after {
  background-color: #949494;
  border-color: #949494;
}

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.switch label {
  cursor: pointer;
}

.switch label input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: #84c7c1;
}

.switch label input[type="checkbox"]:checked + .lever:before,
.switch label input[type="checkbox"]:checked + .lever:after {
  left: 18px;
}

.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #26a69a;
}

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 36px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 15px;
  margin-right: 10px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label .lever:before,
.switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  -webkit-transition: left 0.3s ease, background 0.3s ease,
    -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: left 0.3s ease, background 0.3s ease, -webkit-box-shadow 0.1s ease,
    -webkit-transform 0.1s ease;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease,
    transform 0.1s ease;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease,
    transform 0.1s ease, -webkit-box-shadow 0.1s ease,
    -webkit-transform 0.1s ease;
}

.switch label .lever:before {
  background-color: rgba(38, 166, 154, 0.15);
}

.switch label .lever:after {
  background-color: #f1f1f1;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

input[type="checkbox"]:checked:not(:disabled) ~ .lever:active::before,
input[type="checkbox"]:checked:not(:disabled).tabbed:focus ~ .lever::before {
  -webkit-transform: scale(2.4);
  transform: scale(2.4);
  background-color: rgba(38, 166, 154, 0.15);
}

input[type="checkbox"]:not(:disabled) ~ .lever:active:before,
input[type="checkbox"]:not(:disabled).tabbed:focus ~ .lever::before {
  -webkit-transform: scale(2.4);
  transform: scale(2.4);
  background-color: rgba(0, 0, 0, 0.08);
}

.switch input[type="checkbox"][disabled] + .lever {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.12);
}

.switch label input[type="checkbox"][disabled] + .lever:after,
.switch label input[type="checkbox"][disabled]:checked + .lever:after {
  background-color: #949494;
}
