.Hand-container {
    /*padding-top: 1rem;*/
    font-size: 1.6rem;
    /*max-width: 14rem;*/
    /*min-width: 10rem;*/
    max-width: 98%;
    font-weight: bold;
    /*min-width: 12rem;*/
    /*padding: 0 !important;*/
    /*margin: 0 !important;*/
}
.Hand-card {
    max-height: 17rem;
    padding: 0;
    width: 100%;
    margin: 0;
    min-height: 17rem;
    min-width: 12rem;

    /*min-width: 14rem;*/

    /*padding: 0 !important;*/
    /*margin: 0 !important;*/
    position: relative;
    background-color: #F5F5F5;
}
.Hand-header {
    position: absolute;
    font-size: 2.85rem;
    background-color: white;
    width: 100%;
}
.Hand-headerTwo {
    position: absolute;
    width: 100%;
    /*top: -2rem;*/
    left: 0rem;
    font-size: 2.7rem;
    background-color: white;
    /*width: 14rem;*/
    padding-bottom: 0;
    margin-bottom: 0;

}
.Hand-posicon {
    position: relative;
    left: .5rem;
    background-color: #0b61f6;
    color: white;
}

.Hand-row {
    padding: 0;
    margin: 0;
    /*position: absolute;*/
    top: -1.1rem;
    /*padding-top: 0;*/
    /*padding-bottom: 0;*/
    /*margin-bottom: 0;*/
    /*margin-top: 0;*/
}
.Hand-row_cardstext {
    position: relative;
    left: -1.5rem;
    font-size: 2.5rem;
    /*font-size: 95%;*/
    /*2.3*/
    padding: 0;
    margin: 0;
}
.Hand-suit {
    font-size: 2.45rem !important;
    margin-right: .05rem;
}

.diamond-suit {
    font-size: 2.65rem !important;
    position: relative;
    right: 0.55rem !important;
}

@media screen and (max-width: 500px) {
    .Hand-container {
        width: 99%;
        /*max-width: 6rem;*/
        padding: 0;
        /*max-width: 100%;*/
        margin: 0;
        /*min-width: 100%;*/
        min-width: 9rem;
    }
    .Hand-card {
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        min-width: 9rem;
    }
    .Hand-header {
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        /*font-size: .8rem;*/
    }
    .Hand-headerTwo {
        /*max-width: 10rem;*/
        max-width: 100%;

        /*font-size: .8rem;*/
    }
    .Hand-row {
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        font-size: 2.5rem;
    }
    .Hand-row_cardstext {
        position: relative;
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        /*font-size: 1.85rem;*/

        font-size: 76%;
        /*62%*/
        /*font-size: 95%;*/
    }
    .Hand-suit {
        font-size: 2.5rem;
    }
    .diamond-suit {
        right: -.4rem;
        font-size: 92%;
    }
    .card .card-content {
        padding-right: .2rem;
    }
    .Hand-header {
        font-size: 2.1rem;
    }

    /*.Hand-row_diamond {*/
        /*position: relative;*/
        /*top: .1rem;*/
        /*right: .1rem;*/
    /*}*/
}

@media screen and (max-width: 375px) {
    .Hand-container {
        width: 99%;
        /*max-width: 6.5rem;*/
        padding: 0;
        max-width: 100%;
        margin: 0;
        /*min-width: 100%;*/
        min-width: 7.5rem;
    }
    .Hand-card {
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        min-width: 7.5rem;
    }
    .Hand-header {
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        /*font-size: .8rem;*/
    }
    .Hand-headerTwo {
        /*max-width: 10rem;*/
        max-width: 100%;

        /*font-size: .8rem;*/
    }
    .Hand-row {
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        font-size: 2.25rem;
    }
    .Hand-row_cardstext {
        position: relative;
        /*max-width: 10rem;*/
        /*max-width: 100%;*/
        /*font-size: 1.85rem;*/

        /*font-size: 75%;*/
        font-size: 77%;
        /*65%*/
        /*62%*/
        /*font-size: 95%;*/
    }
    .Hand-suit {
        font-size: 2.5rem;
    }
    .diamond-suit {
        right: -.4rem;
        font-size: 92%;
    }
    .card .card-content {
        padding-right: .2rem;
    }
    .Hand-header {
        font-size: 2.1rem;
    }

    /*.Hand-row_diamond {*/
    /*position: relative;*/
    /*top: .1rem;*/
    /*right: .1rem;*/
    /*}*/
}

/*@media screen and (max-width: 450px) {*/
    /*.Hand-container {*/
        /*width: 89%;*/
        /*!*max-width: 6rem;*!*/
        /*padding: 0;*/
        /*!*max-width: 100%;*!*/
        /*margin: 0;*/
        /*!*min-width: 100%;*!*/
        /*!*min-width: 7rem;*!*/
        /*width: 6rem;*/
    /*}*/

    /*.Hand-card {*/
        /*!*max-width: 10rem;*!*/
        /*!*max-width: 100%;*!*/
        /*min-width: 10rem;*/
    /*}*/
    /*.Hand-header {*/
        /*!*max-width: 10rem;*!*/
        /*!*max-width: 100%;*!*/
        /*!*font-size: .8rem;*!*/
    /*}*/
    /*.Hand-headerTwo {*/
        /*!*max-width: 10rem;*!*/
        /*max-width: 100%;*/

        /*!*font-size: .8rem;*!*/
    /*}*/
    /*.Hand-row {*/
        /*!*max-width: 10rem;*!*/
        /*!*max-width: 100%;*!*/
        /*font-size: 2rem;*/
    /*}*/
    /*.Hand-row_cardstext {*/
        /*position: relative;*/
        /*!*max-width: 10rem;*!*/
        /*!*max-width: 100%;*!*/
        /*!*font-size: 1.85rem;*!*/

        /*font-size: 65%;*/
        /*!*62%*!*/
        /*!*font-size: 95%;*!*/
    /*}*/
    /*.Hand-suit {*/
        /*font-size: 2rem;*/
    /*}*/
    /*.diamond-suit {*/
        /*right: -.4rem;*/
        /*font-size: 92%;*/
    /*}*/
    /*.card .card-content {*/
        /*padding-right: .2rem;*/
    /*}*/
    /*.Hand-header {*/
        /*font-size: 2.1rem;*/
    /*}*/
/*}*/