.Vuln-container {
    position: relative;
    min-height: 12rem;
    max-height: 12rem;
    max-height: 12rem;
    max-width: 12rem;
    min-width: 12rem;
    background-color: #0b5714;
}
.Vuln-board {
    background-color: #5a5a5a;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    min-height: 6rem;
    min-width: 6rem;
    max-width: 6rem;
    border: .3rem solid #0b5714;
}
.Vuln-dealer {
    font-weight: bold;
    font-size: 1.4rem;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.Vuln-Nil {
    background-color: white;
}
.Vuln-Vul {
    background-color: #8d0018;
}
.Vuln-East {
    position: absolute;
    top: 3rem;
    right: 1rem;
    min-height: 6rem;
    max-width: 2rem;
    min-width: 2rem;
    /*border: .2rem solid black;*/

}
.Vuln-West {
    position: absolute;
    top: 3rem;
    left: 1rem;
    min-height: 6rem;
    max-width: 2rem;
    min-width: 2rem;
}
.Vuln-South {
    position: absolute;
    left: 3rem;
    bottom: 1rem;
    min-height: 2rem;
    max-height: 2rem;
    min-width: 6rem;
    max-width: 6rem;
}
.Vuln-North {
    position: absolute;
    left: 3rem;
    top: 1rem;
    min-height: 2rem;
    max-height: 2rem;
    min-width: 6rem;
    max-width: 6rem;
}
