.GenerateBridgeBoardClient-container .row .col label {
    font-size: 1.4rem;
}
/*.GenerateBridgeBoardClient-container .row .col .input {*/
    /*font-size: 1.65rem;*/
/*}*/
.GenerateBridgeBoardClient-input .select-dropdown {
    font-size: 1.6rem !important;
}



.GenerateBridgeBoardClient-bidding_container {
    position: relative;
    height: auto;
    padding-bottom: 5rem;
    margin-bottom: 28.5rem;
    /*right: -36rem;*/
    /*top: 0rem;*/
}
.GenerateBridgeBoardClient-bidding {
    position: relative;
}
.GenerateBridgeBoardClient-single_input {
    font-size: 2.4rem !important;
    position: relative;
    /*position: absolute;*/
    top: -.4rem;
    /*top: 3rem;*/
    left: -.5rem;
    margin: 0 !important;
    width: 13.65rem !important;
    /*height: 1rem;*/
}

@media screen and (min-width: 950px) {
    .Questions_submit-question-button {
        position: relative;
        top: -12rem;
    }
    .GenerateBridgeBoardClient-bidding_container {
        position: absolute;
        right: -36rem;
        top: 0rem;
        margin-bottom: 0 !important;
        padding-bottom: 0;
    }

    .GenerateBridgeBoardClient-bidding {
        min-width: 18rem;
        min-height: 16rem;
        max-width: 18rem;
        max-height: 16rem;
        position: relative;
        left: -20.9rem;
    }

    .GenerateBridgeBoardClient-biddingGrid {

    }
}

/*.GenerateBridgeBoardClient-cardInputs {*/
    /*position: relative;*/
    /*z-index: 2000;*/
/*}*/

/*.GenerateBridgeBoardClient-input_north {*/
    /*top: -10rem;*/
/*}*/
/*.GenerateBridgeBoardClient-input_south {*/

/*}*/
/*.GenerateBridgeBoardClient-input_east {*/

/*}*/
/*.GenerateBridgeBoardClient-input_west {*/

/*}*/

/*.GenerateBridgeBoardClient-hand {*/
    /*cursor: pointer;*/
/*}*/
/*.GenerateBridgeBoardClient-hand:hover {*/
    /*transform: scale(1.03);*/
/*}*/
/*.GenerateBridgeBoardClient-hand:hover * {*/
    /*background-color: red;*/
/*}*/

