.DisplayArticle-container {
  position: relative;
  padding: 10rem 10rem 2rem;
  font-size: 2.1rem;
}
.Display-board_container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.interweave {
  /*font-family: Helvetica;*/
  font-family: Georgia;
  font-weight: 450;
  font-size: 2.45rem;
  padding: 0.5rem;
}
.interweave span {
  color: #8d0018;
  padding: 0;
}
.interweave strong {
  padding: 0;
  font-weight: 700 !important;
}
.interweave * {
  padding: 0;
}

.interweave li {
  list-style-type: circle !important;
  /*square, circle, disc, initial*/
}

.interweave h1,
.interweave h2,
.interweave h3,
.interweave h4,
.interweave h5 {
  margin-top: 4rem;
  font-weight: bold !important;
}

.interweave h1 {
  font-size: 3.6rem !important;
}
.interweave h2 {
  font-size: 3.4rem !important;
}
.interweave h3 {
  font-size: 3.25rem !important;
}
.interweave h4 {
  font-size: 2.7rem !important;
}
.interweave h5 {
  font-size: 2.5rem !important;
}

.DisplayArticle-title {
  font-size: 3.5rem;
  text-align: center;
  font-weight: bold;
  color: #0d47a1;
  margin-bottom: 1.25rem;
}
.DisplayArticle-category {
  width: 12rem;
  font-size: 1.3rem;
  text-align: center;
  background-color: #78909c;
  position: absolute;
  font-weight: bold;
  color: white;
  top: 2.2rem;
  left: 10.5rem;
}
.DisplayArticle-difficulty {
  width: 12rem;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  font-weight: bold;
  color: white;
  top: 0;
  left: 10.5rem;
}
.DisplayArticle-createdAt {
  background-color: #0d47a1;
  padding: 1.1rem;
  color: white;
  position: absolute;
  font-size: 1.3rem;
  font-weight: bold;
  right: 6rem;
  top: 0;
}

@media screen and (max-width: 710px) {
  .DisplayArticle-title {
    position: relative;
    top: 4rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .DisplayArticle-container {
    padding: 2rem;
  }
  .DisplayArticle-category {
    top: 2.2rem;
    left: 0rem;
  }
  .DisplayArticle-difficulty {
    width: 12rem;
    left: 0rem;
  }
  .DisplayArticle-createdAt {
    right: 0rem;
  }
}
