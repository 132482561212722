/*.SideDrawer-outer_container {*/
    /*transition: all .75s ease-in-out;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*position: fixed;*/
    /*z-index: 100;*/
    /*left: 0;*/
    /*top: 0;*/
/*}*/

.SideDrawer-container {
    height: 100%;
    position: fixed;
    width: 250px;
    max-width: 100%;
    left: 0;
    top: 0;
    z-index: 2000;
    background-color: #F5F5F5;
    background-image: linear-gradient(105deg,
                      rgba(255, 255, 255, .9) 0%,
                      rgba(255, 255, 255, .9) 50%,
                      transparent 50%);
    /*padding: 32px 16px;*/
    transition: all .4s ease-in-out;
}
.SideDrawer-User {
    /*padding: 5rem;*/
    margin-top: 1.5rem;
    position: relative;
    top: -2rem;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.SideDrawer-Profile {
    width: 6.5rem;
    height: 6.5rem;
    font-size: 6.5rem !important;
    /*position: relative;*/
    /*top: 0;*/
    /*margin: 0 auto;*/
}
.SideDrawer-Profile-Image {
    width: 6.5rem;
    height: 6.5rem;
    font-size: 6.5rem;
    border-radius: 50%;
}

.SideDrawer-SectionLabel {
    margin-top: .65rem;
    /*text-align: center;*/
    text-transform: capitalize;
    margin-left: 2rem;
    font-size: 1.7rem;
    color: #8b1014;
    opacity: .45;
}

.SideDrawer-name {
    padding-top: 1rem;
    text-align: center;
    font-size: 1.45rem;
}
.SideDrawer-email {
    /*margin: 1rem;*/
    text-align: center;
    font-size: 1.35rem;
}
.SideDrawer-score_number {
    font-size: 2.85rem !important;
}

.SideDrawer-info {
    align-self: left;
    position: relative;
    top: .75rem;
    padding-top: .2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.25rem;
}

.SideDrawer-Divider {
    padding-top: .55rem;
    padding-bottom: .55rem;
    /*background-color: transparent;*/
}
.SideDrawer-MinorLinks {
    padding: 1rem 2rem 2rem;
}
.SideDrawer-MinorLink {
    color: black !important;
    font-size: 1.1rem;
}

/*.SideDrawer-Links {*/
    /*width: 100%;*/
    /*padding: 0;*/
/*}*/
/*.SideDrawer-NavLinks {*/
    /*width: 100%;*/
    /*padding: 0;*/
/*}*/

.SideDrawer-NavLink {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    color: #5a5a5a;
    text-decoration: none;
    text-align: left;
    /*padding-left: .5rem;*/
    /*padding-left: 16px;*/
}
.SideDrawer-NavLink-Active {
    color: #8d0018;
}



.SideDrawer-NavLink-Li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    /*padding-left: 16px;*/
    padding: 3px;
    padding-left: 20px;
}
.SideDrawer-NavLink-Li:hover {
    background-color: #F5F5F5;
}
.SideDrawer-link_icon {
    /*padding-right: 3rem;*/
    position: relative;
    top: .25rem;
    margin-right: 1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 2.1rem;
}
.SideDrawer-link_icon_quiz {
    /*padding-left: .5rem;*/
    /*margin-left: .5rem;*/
    position: relative;
    left: .15rem;
}
.SideDrawer-link_icon_quiz_label {
    position: relative;
    left: .30rem;
}

.SideDrawer-Logo {
    /*position: relative;*/
    width: 12rem;
    height: auto;
    position: absolute;
    bottom: -.25rem;
    left: -.99rem;
}

.SideDrawer-Closed {
    transform: translateX(-100%);
    opacity: 0;
}

.SideDrawer-Open {
    transform: translateX(0);
    opacity: 1;
}

/*.SideDrawer-X-Container {*/
    /*position: relative;*/
    /*transition: all 5s ease-in-out;*/
/*}*/

.SideDrawer-X {
    /*opacity: 1;*/
    transition: all .4s ease-in-out !important;
    transform: scale(.5) rotate(-35deg);
    position: absolute;
    top: .1rem;
    left: 1.75rem;
    font-size: 3.5rem;
    cursor: pointer;
    /*display: none;*/

}

.SideDrawer-X-Closed {
    display: none;
    /*transition: all .5s ease-in-out;*/
    /*transform: scale(.5) rotate(-180deg);*/
}
    /*position: absolute;*/
    /*top: -.75rem;*/
    /*left: 1.45rem;*/
    /*transform: rotate(-180deg);*/
    /*font-size: 4.3rem;*/
    /*cursor: pointer;*/
    /*height: 4rem;*/
    /*width: 4rem;*/
/*}*/

.SideDrawer-X-Open {
    display: block;
    z-index: 3000;
    /*transform: scale(1) rotate(180deg);*/
    /*transition: all .5s ease-in-out;*/
    /*transform: scale(1) rotate(180deg);*/
}
    /*!*opacity: 1;*!*/
    /*position: absolute;*/
    /*top: -.75rem;*/
    /*left: 1.45rem;*/
    /*font-size: 4.3rem;*/
    /*cursor: pointer;*/
    /*z-index: 3000;*/
    /*display: block;*/
    /*transform: rotate(180deg);*/
    /*height: 4rem;*/
    /*width: 4rem;*/
/*}*/




/*}*/
/*.SideDrawer-X-Open {*/
/*cursor: pointer;*/
/*color: #F5F5F5;*/
/*font-size: 5rem;*/
/*position: absolute;*/
/*top: -.7rem;*/
/*left: 1rem;*/
/*z-index: 1000;*/
/*!*height: 4.7rem;*!*/
/*!*width: 4.7rem;*!*/
/*opacity: 1;*/
/*transform: rotateX(-45deg);*/
/*transition: all .3s ease-in-out;*/
/*}*/



/*.Nav-menu-container {*/

/*}*/
/*.Nav-menu-container:hover {*/
/*color: #81c784;*/
/*opacity: 1;*/
/*}*/


.SideDrawer-NavLink:hover .SideDrawer-link_icon {
    color: #8d0018;
}

@media screen and (max-width: 500px) {
    .SideDrawer-User {
        margin-top: 4rem;
    }
    .SideDrawer-container {
        overflow: scroll;
    }
    .SideDrawer-Logo {
        position: relative;
        width: 12rem;
        height: auto;
        /*bottom: -.25rem;*/
        /*left: -.99rem;*/
    }
}

.SideDrawer-NavLink-Disabled {
    opacity: .65;
    cursor: progress !important;
}