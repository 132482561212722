.Vote-container {
    z-index:3000;
    position:absolute;
    top: -1.2rem;
    left: 1.6rem;
}
.Vote-voteCount {
    position: absolute;
    top: 4.5rem;
    font-size: 1.7rem;
    font-weight: bold;
}
.Vote-vote {
    z-index: 3000 !important;
    color: #9e9e9e;
    font-size: 6rem;
    padding: 0 !important;
    margin: 0 !important;
}
.Vote-vote:hover {
    color: #81c784;
}

.Vote-downvote {
    transform: rotate(180deg);
    position: absolute;
    top: -0.65rem;
    left: .1rem;
}
.Vote-downvote:hover {
    color: orangered;
}